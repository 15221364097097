import React, { useContext } from "react"
import styled from "styled-components"
import ContentDiv from "../components/contentDiv"
import Layout from "../components/layout"
import Mail from "../components/mail"
import PhoneNumber from "../components/PhoneNumber"
import Text, { TextType } from "../components/text"
import { BackendContext } from "../services"
import { openPdfBlob } from "../util/pdfUtil"

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
const StyledA = styled.a`
  text-decoration: none;
`
const HeaderDiv = styled.div`
  margin-top: 80px;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
`

const Datenschutz = () => {
  const { getDatenschutz } = useContext(BackendContext)
  const onDownloadClick = () =>
    getDatenschutz().then(blob => openPdfBlob(blob, "dateschutzerklaerung.pdf"))
  return (
    <Layout>
      <ContentDiv>
        <HeaderDiv>
          <Text type={TextType.Header}>Datenschutz</Text>
        </HeaderDiv>
        <Text type={TextType.Body}>
          <p>
            Dietrich und Süß Steuerberater <br />
            Landsberger Strasse 408 <br />
            81241 München <br />
            FON: +49 (0)89 820 8597 0 <br />
            FAX: +49 (0)89 820 8597 55 <br />
          </p>
          <p>
            Datenschutzerklärung für die Internetseite Dietrich und Süß
            Information nach Artikel 13 und 14 DSGVO.pdf.
          </p>
          <StyledButton onClick={onDownloadClick}>
            Pdf Version der Datenschutzerklärung downloaden.
          </StyledButton>
          <p>
            <b>Datenschutzerklärung</b>
          </p>
          <p>
            <b>1.Name und Kontaktdaten des Verantwortlichen</b>
          </p>
          <p>
            Diese Datenschutzerklärung informiert über die Verarbeitung
            personenbezogener Daten auf der Kanzleiwebseite von:
          </p>
          <p>
            Dietrich und Süß Steuerberater <br />
            Landsberger Straße 408 <br />
            81241 München <br />
            Tel: +49 (0)89 820 8597 0 <br />
            E-Mail: <Mail mail="info@dietrichsuess.de" />
          </p>
          <p>
            <b>Kontaktdaten des Datenschutzbeauftragten:</b>
          </p>
          <p>
            Der/die Datenschutzbeauftragte der Kanzlei ist unter der
            vorstehenden Kanzleianschrift und unter{" "}
            <Mail mail="datenschutz@dietrichsuess.de" />
          </p>
          <p>
            <b>2.Umfang und Zweck der Verarbeitung personenbezogener Daten</b>
          </p>
          <p>
            <b>2.1 Aufruf der Webseite</b>
          </p>
          <p>
            Beim Aufruf dieser Webseite https://dietrichsuess.de werden durch
            den Internet-Browser, den der Besucher verwendet, automatisch Daten
            an den Server dieser Webseite gesendet und zeitlich begrenzt in
            einer Protokolldatei (Logfile) gespeichert. Bis zur automatischen
            Löschung werden nachstehende Daten ohne weitere Eingabe des
            Besuchers gespeichert: <br />
            - IP-Adresse des Endgeräts des Besuchers, <br />
            - Datum und Uhrzeit des Zugriffs durch den Besucher, <br />
            - Name und URL der vom Besucher aufgerufenen Seite, <br />
            - Webseite, von der aus der Besucher auf die Kanzleiwebseite gelangt
            (sog. Referrer-URL), <br />- Browser und Betriebssystem des
            Endgeräts des Besuchers sowie der Name des vom Besucher verwendeten
            Access-Providers.
          </p>
          <p>
            Die Verarbeitung dieser personenbezogenen Daten ist gem. Art. 6 Abs.
            1 Satz 1 Buchst. f) DSGVO gerechtfertigt. Die Kanzlei hat ein
            berechtigtes Interesse an der Datenverarbeitung zu dem Zweck, <br />
            - die Verbindung zur Webseite der Kanzlei zügig aufzubauen, <br />-
            eine nutzerfreundliche Anwendung der Webseite zu ermöglichen, <br />
            - die Sicherheit und Stabilität der Systeme zu erkennen und zu
            gewährleisten und <br />- die Administration der Webseite zu
            erleichtern und zu verbessern. <br />
          </p>
          <p>
            Die Verarbeitung erfolgt ausdrücklich nicht zu dem Zweck,
            Erkenntnisse über die Person des Besuchers der Webseite zu gewinnen.
          </p>
          <p>
            <b>3.Weitergabe von Daten</b>
          </p>
          <p>
            - nach Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die betroffene
            Person ausdrücklich dazu eingewilligt wurde, <br />
            - die Weitergabe nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO zur
            Geltendmachung, Aus-übung oder Verteidigung von Rechtsansprüchen
            erforderlich ist und kein Grund zur Annahme besteht, dass die
            betroffene Person ein überwiegendes schutzwürdiges Interesse an der
            Nichtweitergabe ihrer Daten hat, <br />
            - für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst. c)
            DSGVO eine gesetzliche Verpflichtung besteht, und/oder <br />
            - dies nach Art. 6 Abs. 1 Satz 1 Buchst. b) DSGVO für die Erfüllung
            eines <br />
            Vertragsverhältnisses mit der betroffenen Person erforderlich ist.{" "}
            <br />
            In anderen Fällen werden personenbezogene Daten nicht an Dritte
            weitergegeben.
          </p>
          <p>
            <b>4.Ihre Rechte als betroffene Person</b>
            Soweit Ihre personenbezogenen Daten anlässlich des Besuchs unserer
            Webseite verarbeitet werden, stehen Ihnen als „betroffene Person“ im
            Sinne der DSGVO folgende Rechte zu:
          </p>
          <p>
            <b>4.1 Auskunft </b>
          </p>
          <p>
            Sie können von uns Auskunft darüber verlangen, ob personenbezogene
            Daten von Ihnen bei uns verarbeitet werden. Kein Auskunftsrecht
            besteht, wenn die Erteilung der begehrten Informationen gegen die
            Verschwiegenheitspflicht gem. § 57 Abs. 1 StBerG verstoßen würde
            oder die Informationen aus sonstigen Gründen, insbesondere wegen
            eines überwiegenden berechtigten Interesses eines Dritten, geheim
            gehalten werden müssen. Hiervon abweichend kann eine Pflicht zur
            Erteilung der Auskunft bestehen, wenn insbesondere unter
            Berücksichtigung drohender Schäden Ihre Interessen gegenüber dem
            Geheimhaltungsinteresse überwiegen. Das Auskunftsrecht ist ferner
            ausgeschlossen, wenn die Daten nur deshalb gespeichert sind, weil
            sie aufgrund gesetzlicher oder satzungsmäßiger Aufbewahrungsfristen
            nicht gelöscht werden dürfen oder ausschließlich Zwecken der
            Datensicherung oder der Datenschutzkontrolle dienen, sofern die
            Auskunftserteilung einen unverhältnismäßig hohen Aufwand erfordern
            würde und die Verarbeitung zu anderen Zwecken durch geeignete
            technische und organisatorische Maßnahmen ausgeschlossen ist. Sofern
            in Ihrem Fall das Auskunftsrecht nicht aus-geschlossen ist und Ihre
            personenbezogenen Daten von uns verarbeitet werden, können Sie von
            uns Auskunft über folgende Informationen verlangen: <br />
            - Zwecke der Verarbeitung, <br />- Kategorien der von Ihnen
            verarbeiteten personenbezogenen Daten, <br />
            - Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre
            personenbezogenen Daten offen gelegt werden, insbesondere bei
            Empfängern in Drittländern, <br />
            - falls möglich die geplante Dauer, für die Ihre personenbezogenen
            Daten gespeichert werden oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung der Speicherdauer,
            <br />
            - das Bestehen eines Rechts auf Berichtigung oder Löschung oder
            Einschränkung der Verarbeitung der Sie betreffenden
            personenbezogenen Daten oder eines Widerspruchs-rechts gegen diese
            Verarbeitung,
            <br />
            - das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde für
            den Datenschutz, <br />
            - sofern die personenbezogenen Daten nicht bei Ihnen als betroffene
            Person erhoben worden sind, die verfügbaren Informationen über die
            Datenherkunft, <br />
            - ggf. das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling und aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und angestrebten Auswirkungen
            automatisierter Entscheidungsfindungen, <br />
            - ggf. im Fall der Übermittlung an Empfänger in Drittländern, sofern
            kein Beschluss der EU-Kommission über die Angemessenheit des
            Schutzniveaus nach Art. 45 Abs. 3 DSGVO vorliegt, Informationen
            darüber, welche geeigneten Garantien gem. Art. 46 Abs. 2 DSGVO zum
            Schutze der personenbezogenen Daten vorgesehen sind. <br />
          </p>
          <p>
            <b>4.2 Berichtigung und Vervollständigung </b>
          </p>
          <p>
            Sofern Sie feststellen, dass uns unrichtige personenbezogene Daten
            von Ihnen vorliegen, können Sie von uns die unverzügliche
            Berichtigung dieser unrichtigen Daten verlangen. Bei unvollständigen
            Sie betreffenden personenbezogenen Daten können sie die
            Vervollständigung verlangen.
          </p>
          <p>
            <b>4.3 Löschung</b>
          </p>
          <p>
            Sie haben ein Recht auf Löschung („Recht auf Vergessen werden“),
            sofern die Verarbeitung nicht zur Ausübung des Rechts auf freie
            Meinungsäußerung, des Rechts auf Information oder zur Erfüllung
            einer rechtlichen Verpflichtung oder zur Wahrnehmung einer Aufgabe,
            die im öffentlichen Interesse liegt, erforderlich ist und einer der
            nachstehenden Gründe zutrifft: <br />
            - Die personenbezogenen Daten sind für die Zwecke, für die sie
            verarbeitet wurden, nicht mehr notwendig. <br />- Die
            Rechtfertigungsgrundlage für die Verarbeitung war ausschließlich
            Ihre Einwilligung, welche Sie widerrufen haben. <br />- Sie haben
            Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
            eingelegt, die wir öffentlich gemacht haben. <br />- Sie haben
            Widerspruch gegen die Verarbeitung von uns nicht öffentlich
            gemachter personenbezogener Daten eingelegt und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor. <br />-
            Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet. <br />
            - Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            gesetzlichen Verpflichtung, der wir unterliegen, erforderlich.{" "}
            <br />
          </p>
          <p>
            Kein Anspruch auf Löschung besteht, wenn die Löschung im Falle
            rechtmäßiger nicht automatisierter Datenverarbeitung wegen der
            besonderen Art der Speicherung nicht oder nur mit unverhältnismäßig
            hohem Aufwand möglich und Ihr Interesse an der Löschung gering ist.
            In diesem Fall tritt an die Stelle einer Löschung die Einschränkung
            der Verarbeitung.
          </p>
          <p>
            <b>4.4 Einschränkung der Verarbeitung</b>
          </p>
          <p>
            Sie können von uns die Einschränkung der Verarbeitung verlangen,
            wenn einer der nachstehenden Gründe zutrifft: <br />
            - Sie bestreiten die Richtigkeit der personenbezogenen Daten. Die
            Einschränkung kann in diesem Fall für die Dauer verlangt werden, die
            es uns ermöglicht, die Richtigkeit der Daten zu überprüfen. <br />
            - Die Verarbeitung ist unrechtmäßig und Sie verlangen statt Löschung
            die Einschränkung der Nutzung Ihrer personenbezogenen Daten. <br />
            - Ihre personenbezogenen Daten werden von uns nicht länger für die
            Zwecke der Verarbeitung benötigt, die Sie jedoch zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen benötigen. <br />
            - Sie haben Widerspruch gem. Art. 21 Abs. 1 DSGVO eingelegt. Die
            Einschränkung der Verarbeitung kann solange verlangt werden, wie
            noch nicht feststeht, ob unsere berechtigten Gründe gegenüber Ihren
            Gründen überwiegen. <br />
          </p>
          <p>
            Einschränkung der Verarbeitung bedeutet, dass die personenbezogenen
            Daten nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
            einer anderen natürlichen oder juristischen Person oder aus Gründen
            eines wichtigen öffentlichen Interesses verarbeitet werden. Bevor
            wir die Einschränkung aufheben, haben wir die Pflicht, Sie darüber
            zu unterrichten.
          </p>
          <p>
            <b>4.5 Datenübertragbarkeit</b>
          </p>
          <p>
            Sie haben ein Recht auf Datenübertragbarkeit, sofern die
            Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 Satz 1 Buchst. a)
            oder Art. 9 Abs. 2 Buchst. a) DSGVO) oder auf einem Vertrag beruht,
            dessen Vertragspartei Sie sind und die Verarbeitung mithilfe
            automatisierter Verfahren erfolgt. Das Recht auf
            Datenübertragbarkeit beinhaltet in diesem Fall folgende Rechte,
            sofern hierdurch nicht die Rechte und Freiheiten anderer Personen
            beeinträchtigt werden: Sie können von uns verlangen, die
            personenbezogenen Daten, die Sie uns bereit gestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne
            Behinderung unserseits zu übermitteln. Soweit technisch machbar,
            können Sie von uns verlangen, dass wir Ihre personenbezogenen Daten
            direkt an einen anderen Verantwortlichen übermitteln.
          </p>
          <p>
            <b>4.6 Widerspruch </b>
          </p>
          <p>
            Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst. e) DSGVO
            (Wahrnehmung einer Aufgabe im öffentlichen Interesse oder in
            Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1 Satz 1 Buchst.
            f) DSGVO (berechtigtes Interesse des Verantwortlichen oder eines
            Dritten) beruht, haben Sie das Recht, aus Gründen, die sich aus
            Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
            der Sie betreffenden personenbezogenen Daten Wider-spruch
            einzulegen. Das gilt auch für ein auf Art. 6 Abs. 1 Satz 1 Buchst.
            e) oder Buchst. f) DSGVO gestütztes Profiling. Nach Ausübung des
            Widerspruchsrechts verarbeiten wir Ihre personenbezogenen Daten
            nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe
            für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Sie
            können jederzeit Widerspruch gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten zu Zwecken der Direktwerbung
            einlegen. Das gilt auch für ein Profiling, das mit einer solchen
            Direktwerbung in Verbindung steht. Nach Ausübung dieses
            Widerspruchs-rechts werden wir die betreffenden personenbezogenen
            Daten nicht mehr für Zwecke der Direktwerbung verwenden. Sie haben
            die Möglichkeit, den Widerspruch telefonisch, per E-Mail, ggf. per
            Telefax oder an unsere zu Beginn dieser Datenschutzerklärung
            aufgeführte Postadresse unserer Kanzlei form-los mitzuteilen.
          </p>
          <p>
            <b>4.7 Wiederruf einer Einwilligung </b>
          </p>
          <p>
            Sie haben das Recht, eine erteilte Einwilligung jederzeit mit
            Wirkung für die Zukunft zu wider-rufen. Der Widerruf der
            Einwilligung kann telefonisch, per E-Mail, ggf. per Telefax oder an
            unsere Postadresse formlos mitgeteilt werden. Durch den Widerruf
            wird die Rechtmäßigkeit der Datenverarbeitung, die aufgrund der
            Einwilligung bis zum Eingang des Widerrufs erfolgt ist, nicht
            berührt. Nach Eingang des Widerrufs wird die Datenverarbeitung, die
            ausschließlich auf Ihrer Einwilligung beruhte, eingestellt.
          </p>
          <p>
            <b>4.8 Beschwerde</b>
          </p>
          <p>
            Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
            betreffenden personenbezogenen Daten rechtswidrig ist, können Sie
            Beschwerde bei einer Aufsichtsbehörde für den Daten-schutz einlegen,
            die für den Ort Ihres Aufenthaltes oder Arbeitsplatzes oder für den
            Ort des mutmaßlichen Verstoßes zuständig ist.
          </p>
          <p>
            <b>5.Stand und Aktualisierung dieser Datenschutzerklärung</b>
          </p>
          <p>
            Diese Datenschutzerklärung hat den Stand vom 18. Januar 2020. Wir
            behalten uns vor, die Datenschutzerklärung zu gegebener Zeit zu
            aktualisieren, um den Datenschutz zu verbessern und/oder an
            geänderte Behördenpraxis oder Rechtsprechung anzupassen.
          </p>
        </Text>
        <Text type={TextType.Body}>
          <p>
            <b>Information für Betroffene nach Art. 13 und Art. 14 EU-DSGVO</b>
          </p>
          <p>
            <b>
              1-Hiermit informieren wir über Verarbeitung von Daten in unserer
              Kanzlei.
            </b>
          </p>
          <p>
            <b>Verantwortliche Stelle:</b>
          </p>
          <p>
            Dietrich und Süß Steuerberater <br />
            Landsberger Straße 408 <br />
            81241 München <br />
            Tel: <PhoneNumber numPre="89" numSuf="82085970" /> <br />
            E-Mail: <Mail mail="kontakt07@dietrichsuess.de" />
          </p>
          <p>
            <b>Kontaktdaten des Datenschutzbeauftragten:</b>
          </p>
          <p>
            DATA Security GmbH <br />
            Herr Dominik Mikulovic <br />
            Bodenseestr. 12 <br />
            83059 Kolbermoor <br />
            Tel: <PhoneNumber numPre="8031" numSuf="4696931" /> <br />
            E-Mail: <Mail mail="info@data-security.one" /> <br />
            Internet:{" "}
            <StyledA href="www.data-security.one">
              www.data-security.one
            </StyledA>
          </p>
          <p>
            <b>
              2-In unserer Kanzlei werden folgende personenbezogenen Daten
              verarbeitet:
            </b>
          </p>
          <p>
            Vor- und Nachname, Anrede, ggf. Titel <br />
            Postanschrift <br />
            Telefonnummer <br />
            Faxnummer <br />
            E-Mail-Adresse <br />
          </p>
          <p>
            Abhängig vom erteilten Auftrag weitere Informationen und Daten damit
            der Auftrag erfüllt werden kann.
          </p>
          <p>
            <b>
              3-Die Zwecke für die Verarbeitung personenbezogener Daten sind:
            </b>
          </p>
          <p>
            Ausführung des Auftrags des Mandanten einschließlich der
            Korrespondenz Erfüllung vertraglichen und gesetzlichen Pflichten als
            Steuerberater Verarbeitung im Rahmen von gegenseitigen Ansprüchen
            aus dem Steuerberatungsvertrag (z. B. Rechnungsstellung).
          </p>
          <p>
            <b>
              4-Die Rechtsgrundlagen für die Verarbeitung personenbezogener
              Daten sind:
            </b>
          </p>
          <p>
            Einwilligung der betroffenen Personen für einen oder mehrere der
            oben genannten Zwecke (Art. 6 Abs.1 a DS-GVO). <br />
            Erforderliche Verarbeitung zur Durchführung vorvertraglicher
            Maßnahmen, die auf Anfrage der Betroffenen Person erfolgen (Art. 6
            Abs.1 b DS-GVO). <br />
            Vertragserfüllung, wobei die betroffene Person Vertragspartei ist
            (Art. 6 Abs.1 b DS-GVO). <br />
            Rechtliche Verpflichtung, der der Verantwortliche unterliegt (Art. 6
            Abs.1 c DS-GVO). <br />
            Wahrung der berechtigten Interessen des Verantwortlichen oder eines
            Dritten, wobei die Grundrechte der betroffen Person nicht überwiegen
            (Art. 6 Abs.1 f DS-GVO). <br />
          </p>
          <p>
            <b>
              5-Im Rahmen des Mandatsverhältnisses geben wir personenbezogene
              Daten an folgende Empfänger weiter:
            </b>
          </p>
          <p>
            <b>Intern:</b>
          </p>
          <p>
            Mitarbeiter der Kanzlei <br />
            Partner der Kanzlei <br />
          </p>
          <p>
            <b>Extern:</b>
          </p>
          <p>
            Dienstleister (Art. 28 DSGVO, AV) <br />
            Bei Beauftragung an Geschäftspartner des Mandanten <br />
            Bei Beauftragung an Geschäftspartner des Betroffenen <br />
            Behörden <br />
            Institutionen <br />
            Finanzamt <br />
            Kreditinstitute <br />
            Versicherungen <br />
          </p>
          <p>
            <b>
              6-Datenübermittlung in Drittstatten und an internationale
              Organisation:
            </b>
          </p>
          <p>
            Eine Datenübermittlung in Drittstaaten und an internationale
            Organisationen findet nur statt, soweit dies zur Ausführung des
            Mandatsvertrages erforderlich. Eine Übermittlung findet auch statt,
            wenn Sie uns damit beauftragt haben oder die Übermittlung gesetzlich
            erforderlich bzw. zulässig ist. In diesen Fällen übermitteln wir
            ausschließlich an Empfänger, die den Schutz Ihrer Daten nach den
            Vorschriften der DSGVO für die Übermittlung an Drittländer (Art. 44
            bis 49 DSGVO) sicherstellen.
          </p>
          <p>
            <b>7-Angabe zu Löschfristen:</b>
          </p>
          <p>
            Die Daten werden entsprechend § 257 HGB, § 147 AO und § 66 StBerG
            gespeichert und archiviert. Nach Ablauf der gesetzlichen
            Aufbewahrungspflichten werden die Daten gelöscht.
          </p>
          <p>
            Eine Aufbewahrung über die gesetzlich vorgeschriebene Frist ist
            eventuell zur Abwehr von Haftungsansprüchen möglich. Dies gilt auch,
            wenn die Vertragsvereinbarung eine längere Aufbewahrungsfrist
            vorsieht.
          </p>
          <p>
            <b>8-Ihre Rechte als betroffene Person:</b>
          </p>
          <p>
            Recht auf Auskunft nach Art. 15 DSGVO <br />
            Recht auf Berichtigung nach Art. 16 DSGVO <br />
            Recht auf Löschung nach Art. 17 DSGVO <br />
            Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO <br />
            Recht auf Übertragung Ihrer Daten nach Art. 20 DSGVO <br />
            Recht auf Widerruf Ihrer Einwilligung nach Art. 7 Abs. 3 DSGVO{" "}
            <br />
            Recht auf Beschwerde bei der Aufsichtsbehörde nach Art. 77 Abs. 1
            DSGVO <br />
          </p>
          <p>
            <StyledA href="https://www.lda.bayern.de/de/index.html">
              https://www.lda.bayern.de/de/index.html
            </StyledA>
          </p>
          <p>Stand: Januar 2020</p>
          <p>Wir behalten uns vor Aktualisierungen durchzuführen.</p>
        </Text>
      </ContentDiv>
    </Layout>
  )
}

export default Datenschutz
